import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { Button, Form, Input } from 'antd';
import withLayout from '../../HOC/withLayout';
import { WithLayoutProps } from '../Homepage/HomepageView';
import { verifyAuthentication } from '../../api/authentication';
import { useAuth } from '../../HOC/AuthenProvider';
import { BEARER_TOKEN } from '../../utils/Constants';

interface LoginProps {
  userName: string;
  password: string;
}

const Login = ({ layout }: WithLayoutProps) => {
  const [formLogin] = Form.useForm<LoginProps>();
  const { isAuthenticated, setAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => localStorage.removeItem(BEARER_TOKEN), []);

  const onFinish = () => {
    const form = formLogin.getFieldsValue();

    verifyAuthentication(form).then(response => {
      if (response?.statusCode === 200) {
        setAuthenticated(true);
        localStorage.setItem(BEARER_TOKEN, response.response.token)
        navigate("/admin");
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (

    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}>
      <div style={{ width: '400px', padding: '24px', backgroundColor: '#f0f2f5', borderRadius: '8px' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '24px' }}>Đăng nhập</h1>
        <Form
          name="basic"
          style={{ maxWidth: 'calc(100vw - 50px)', width: '100%' }}
          form={formLogin}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="userName"
            rules={[{ required: true, message: 'Tên tài khoản' }]}
          >
            <Input placeholder="Tài khoản" style={{ fontSize: '20px' }} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Mật khẩu' }]}
          >
            <Input.Password placeholder="Mật khẩu" style={{ fontSize: '20px' }} />
          </Form.Item>

          <Form.Item >
            <Button type="primary" htmlType="submit">
              Đăng nhập
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )

};


export default withLayout(Login);