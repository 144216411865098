import { groupEnd } from "console";
import { DataSingleCardFromDB, EditForm, SingleCardSummary } from "../interfaces/interfaces";

const DataCategory = [
    {
        id: 1,
        category: 'face',
        label: 'Da mặt',
        pathname: 'da-mat',
        pageName: 'Chăm sóc da mặt',
        color: '#BAC189'
    },
    {
        id: 2,
        category: 'body',
        label: 'Body',
        pathname: 'body',
        pageName: 'Chăm sóc body',
        color: '#00BBCB'
    },
    {
        id: 3,
        category: 'hair',
        label: 'Tóc',
        pathname: 'toc',
        pageName: 'Chăm sóc tóc',
        color: '#006E61'
    },
    {
        id: 4,
        category: 'makeup',
        label: 'Trang điểm',
        pathname: 'trang-diem',
        pageName: 'Trang điểm',
        color: '#F49269'
    },
    // {
    //     id: 5,
    //     category: 'nutrition',
    //     label: 'Dinh dưỡng',
    //     pathname: '/nutritious-food',
    //     pageName: 'Thực phẩm dinh dưỡng',
    //     color: '#8B7444'
    // },
    // {
    //     id: 6,
    //     category: 'other',
    //     label: 'Khác',
    //     pathname: '/khac',
    //     pageName: 'Sản phẩm khác',
    //     color: '#8B7664'
    // },
    {
        id: 7,
        category: 'mask',
        label: 'Mặt nạ',
        pathname: 'mat-na',
        pageName: 'Mặt nạ',
        color: '#8B7444'
    },
    {
        id: 8,
        category: 'cleaning-gel',
        label: 'Sữa rửa mặt',
        pathname: 'sua-rua-mat',
        pageName: 'Sữa rửa mặt',
        color: '#ba43c7'
    },
];

const promotion = {
    category: 'promotion',
    label: 'Khuyến mãi',
    pathname: '/promotion',
    pageName: 'Khuyến mãi',
    color: '#43c781'
};

const DrawerItems = [
    {
        id: 1,
        text: 'Sản phẩm',
        name: 'products',
        nestedItems: DataCategory
    },
    {
        id: 2,
        text: 'Khuyến mãi',
        name: 'promotion',
        nestedItems: []
    },
    // {
    //     id: 3,
    //     text: 'Loại sản phẩm',
    //     name: 'categories',
    //     nestedItems: []
    // },
    // {
    //     id: 4,
    //     text: 'Hình ảnh',
    //     name: 'images',
    //     nestedItems: []
    // },

]

const findColorByCat = (categoryCode: string | number | undefined | null): string => {
    if (categoryCode === '' || undefined || null) { return '#ba43c7' };

    if (typeof categoryCode === "string") {
        return (DataCategory.find(e => e.category === categoryCode))!.color;
    } else {
        return (DataCategory.find(e => e.id === categoryCode))!.color;
    }
};

const formatNumber = (num: number | null | undefined): string => {
    const formatter = new Intl.NumberFormat("en-US");
    if (typeof num === 'number') return formatter.format(num).replace(",", ".")
    else return 'Đang cập nhật giá'
};

const findCatFromCatCode = (catCode: string | number | undefined | null): string => {
    if (catCode === '' || undefined || null) { return '' };

    if (typeof catCode === "string") {
        return (DataCategory.find(e => e.category === catCode))!.label;
    } else {
        return (DataCategory.find(e => e.id === catCode))!.label;
    }
};

const findCategoryCodeFromPathname = (pathname: string): number | undefined => {
    if (DataCategory.find(e => e.pathname === pathname)) {
        return (DataCategory.find(e => e.pathname === pathname))!.id;
    }
    return undefined;
};

const findPageName = (pathname: string): string => {
    let pageName: string = '';
    DataCategory.forEach(page => {
        if (page.pathname === pathname) {
            pageName = page.pageName;
        };
    });

    return pageName;
}


const truncateText = (text: string, limit: number): string => {
    if (text.length > limit) {
        return text.slice(0, limit) + '...';
    } else {
        return text;
    }
}

const transformDataToDataForm = (data: DataSingleCardFromDB | null) => {
    if (data) {
        let dataForm: EditForm = {
            id: data.id,
            name: data.name,
            image: data.image ? {
                uid: data.image?.id.toString(),
                name: data.image?.name,
                status: 'done',
                url: data.image.urlCloudinary,
            } : '',
            subImages: data.subImages
                ? data.subImages.map((image) => {
                    return (
                        {
                            uid: image.id.toString(),
                            name: image?.name,
                            status: 'done',
                            url: image.urlCloudinary,
                        }
                    )
                })
                : [],
            description: data.description,
            category: (data.category.id).toString(),
            price: data.price,
            capacity: data.capacity,
            origin: data.origin ? data.origin : '',
            expiredIn: data.expiredIn ? data.expiredIn : '',
            notice: data.notice ? data.notice : '',
            use: data.use ? data.use : '',
            usage: data.usage ? data.usage : '',
            ingredients: data.ingredients ? data.ingredients : '',
            promotion: data.promotion ? data.promotion : 0,
            program: data.program ? data.program : '',
        };
        return dataForm;
    }
    return null;
}

const transformDataToDataSingleCardSummary = (data: any | null) => {
    if (data) {
        let dataSummary: SingleCardSummary = {
            id: data.id,
            name: data.name,
            urlCloudinary: data.image?.urlCloudinary,
            description: data.description,
            codeCategory: data.category.id,
            price: data.price,
            capacity: data.capacity,
        };
        return dataSummary;
    }
    return null;
}

const findProductsWithSameCategory = (listProducts: SingleCardSummary[]): SingleCardSummary[][] => {
    let newList: any = [];
    if (listProducts) {
        listProducts.forEach((product: SingleCardSummary) => {
            const group = newList?.find((group: any) => {
                return group.length > 0 && group[0].codeCategory === product.codeCategory;
            });
            if (group) {
                group.push(product);
            } else {
                newList.push([product]);
            }
        });
    }
    return newList;
};


export {
    findColorByCat,
    formatNumber,
    findCatFromCatCode,
    truncateText,
    transformDataToDataForm,
    transformDataToDataSingleCardSummary,
    findProductsWithSameCategory,
    findCategoryCodeFromPathname,
    findPageName,
    DataCategory,
    DrawerItems
}