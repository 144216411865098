import React from 'react';
import Layout from '../components/Layout/Layout';

type Props = {
  layout?: boolean;
};

function withLayout<T extends Props>(
  Component: React.ComponentType<T>
): React.FC<T> {
  const WrappedComponent: React.FC<T> = ({ layout, ...props }) => {
    if (layout) {
      return (
        <Layout>
          <Component {...props as T} />
        </Layout>
      );
    } else {
      return <Component {...props as T} />;
    }
  };

  return WrappedComponent;
}

export default withLayout;