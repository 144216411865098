import { useEffect } from 'react';

interface NotificationProps {
    isOpen: boolean;
    isSuccess: boolean;
    message?: string;
    closeModalInfo: () => void;
}

export default function Notification({isOpen, isSuccess, closeModalInfo}: NotificationProps) {

    useEffect(() => {
        const timer = setTimeout(() => {
            closeModalInfo();
        }, 4000);
        return () => clearTimeout(timer);
    }, [isOpen]);

    return (
        <div className='notification'
            style={{
                opacity: isOpen ? 1 : 0,
                transition: 'opacity 1s ease-in-out',
                backgroundColor: isSuccess ? '#00b894' : 'red',
                color: '#fff',
                borderRadius: '4px',
                padding: '10px',
                position: 'fixed',
                top: '5%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: isOpen ? 9999 : 0
            }}
        >
            {isSuccess ? 'Save successful!' : 'Save fail'}
        </div>
    );
}

