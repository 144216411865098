import { Container } from '@mui/material';

interface HeaderProps {
    title?: string;
}

const Header = ({ title }: HeaderProps) => {

    return (
        <Container maxWidth='lg' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1>{title}</h1>
        </Container>
    )
}

export default Header;