import axios, { AxiosError } from 'axios';

const apiHost = process.env.REACT_APP_API_KEY;

const verifyAuthentication = async (authenticateForm: any) => {
    try {
      const response = await axios.post(`${apiHost}/auth/authenticate`, authenticateForm);
      const { data, status } = response;
      return { response: data, statusCode: status };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  export {verifyAuthentication};