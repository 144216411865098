import { useContext, useState, useEffect, useRef } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import PhoneForwardedRoundedIcon from '@mui/icons-material/PhoneForwardedRounded';
import { mainColor2 } from '../../utils/Theme';
import { BottomScrollContext } from '../../context/BottomScrollContext ';
import { useMediaQuery } from '@mui/material';
import './FloatButton.scss';

export const FloatButton = () => {
    const { isBottom } = useContext(BottomScrollContext);
    const isSmallScreen = useMediaQuery('(max-width:640px)');
    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{ position: 'fixed', bottom: '24px', right: `${isSmallScreen ? '30%' : '10%'}`, zIndex: 1700 }}
            style={{ visibility: isBottom ? 'hidden' : 'visible' }}
        >
            <a href={`tel: 0939018486`} className='tel-btn'>
                <Chip
                    icon={<PhoneForwardedRoundedIcon sx={{ fontSize: "2rem", color: '#FFF !important', border: '1px solid #FFF', padding: '3px', borderRadius: '10px' }} />}
                    label={'09 39 01 84 86'}
                    sx={{
                        width: '18rem',
                        fontSize: '1.4rem',
                        fontWeight: '500',
                        minHeight: '4rem',
                        backgroundColor: mainColor2,
                        color: '#FFF',
                        justifyContent: 'space-around',
                        boxShadow: '2px 3px 5px #73af9b',
                        '& .MuiChip-label': {
                            padding: '1rem'
                        }
                    }}
                />
            </a>
        </Stack>
    );
}