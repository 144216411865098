
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { mainColor3 } from '../../utils/Theme';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface IconBreadcrumbsProps {
  pageName: string;
  pageUrl: string;
  handlePageUrl: (pageUrl: string) => void;
}

export default function IconBreadcrumbs({ pageName, pageUrl, handlePageUrl }: IconBreadcrumbsProps) {
  const location = useLocation();
  const { pathname } = location;
  return (
    <div role="presentation" style={{ marginTop: '3rem', marginLeft: '2rem' }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={'/'} style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="medium" />
          {'Trang chủ'}
        </Link>
        {pageName.split('/').map((page: string, i: number) => {
          let path = pathname.split('/').slice(0, i + 2).join('/');
          return (
            <Link key={path} to={path}>
              {page}
            </Link>)
        }
        )}

      </Breadcrumbs>
    </div>
  );
}