
import { useEffect, useState } from "react";
import SwiperCore, { EffectCoverflow, Pagination, Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Banner.scss";

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/autoplay';

SwiperCore.use([EffectCoverflow, Pagination, Navigation, Autoplay]);

const prefix = process.env.PUBLIC_URL;

interface ImagesProps {
    imageUrl: string
}

const Banner = () => {
    const [listImages, setListImages] = useState<ImagesProps[]>([]);

    useEffect(() => {
        setListImages([
            { imageUrl: `${prefix}/images/banner_photo/horizontal_photo/shipping_banner.png` },
            { imageUrl: `${prefix}/images/banner_photo/horizontal_photo/policy_banner_2.png` },
            { imageUrl: `${prefix}/images/banner_photo/horizontal_photo/banner_3.png` },
            { imageUrl: `${prefix}/images/banner_photo/horizontal_photo/photo_3.jpg` },
            { imageUrl: `${prefix}/images/banner_photo/horizontal_photo/photo_5.jpg` },
        ])
    }, []);
    return (
        <>
            <div className="banner">
                <div className="banner__swipper" style={{ height: "100%" }}>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {listImages.map((image, i) =>
                            <SwiperSlide
                                key={i}
                            >
                                <img
                                    className={'swiper-image'}
                                    src={image.imageUrl}
                                    alt={`image-${i}`}
                                />
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default Banner;