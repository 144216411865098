import React from 'react';

interface BottomScrollContextProps {
  isBottom: boolean;
  setIsBottom: (isBottom: boolean) => void;
}

export const BottomScrollContext = React.createContext<BottomScrollContextProps>({
  isBottom: false,
  setIsBottom: () => {},
});