import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { DataCategory } from '../../utils/Utils';
import { StyledEngineProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import './ResponsiveAppBar.scss';

function ResponsiveAppBar() {
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const isMediumScreen = useMediaQuery('(max-width:820px)');
  const isMediumLargeScreen = useMediaQuery('(max-width:1180px)');

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (

    <StyledEngineProvider injectFirst>
      <AppBar className="AppBar" style={isMediumLargeScreen ? { position: 'static', height: '110px' } : { position: 'fixed', top: 0, zIndex: 1700, height: '100px' }}>
        <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
          <Toolbar disableGutters sx={{ width: '100%', justifyContent: (isMediumScreen ? 'center' : 'unset') }}>
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
            {isMediumScreen ?
              <Link to='/'>
                <img
                  src={`https://res.cloudinary.com/dyc65pvi8/image/upload/v1684058089/beautyshop/logo/logo_2_yfnjsb.png`}
                  alt="logo"
                  style={{ width: 'auto', height: '80px', marginTop: '40px', marginBottom: '20px' }}
                />
              </Link>

              : <Link to='/'>
                <img
                  src={`https://res.cloudinary.com/dyc65pvi8/image/upload/v1684058089/beautyshop/logo/logo_2_yfnjsb.png`}
                  alt="logo"
                  style={{ width: 'auto', height: '70px', marginRight: '10px' }}
                />
              </Link>}

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {DataCategory.map((page) => (

                <Button
                  className='Button'
                  color='secondary'
                  key={page.pageName}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  component={Link} to={`/san-pham/${page.pathname!}`}
                >
                  {page.pageName}
                </Button>

              ))}
              <Button
                className='Button'
                color='secondary'
                key={'promotion'}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
                component={Link} to={'/san-pham/khuyen-mai'}
              >
                Khuyến mãi
              </Button>

            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </StyledEngineProvider>

  );
}
export default ResponsiveAppBar;