import React from 'react';
import { Container } from '@mui/material';
import ResponsiveAppBar from '../ResponsiveAppBar/ResponsiveAppBar';
import { Footer } from '../Footer/Footer';

interface Props {
    children: React.ReactNode;
};

function Layout({ children }: Props) {
    return (
        <>
            <Container style={{ minHeight: '100vh', padding: 0, maxWidth: '1400px' }}>
                <ResponsiveAppBar />
                {children}
            </Container>
            <Footer />
        </>

    );
}

export default Layout;