import { useEffect, useState } from 'react';
import { Avatar } from '@mui/material';
import { DataSingleCardFromDB } from '../../../interfaces/interfaces';
import { mainColor2 } from '../../../utils/Theme';
import './ImagesSection.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Typography from '@mui/material/Typography';

interface ImagesSectionProps {
    dataFromDescriptionModal: DataSingleCardFromDB | null;
}

export const ImagesSection = ({ dataFromDescriptionModal }: ImagesSectionProps) => {

    const [data, setData] = useState<DataSingleCardFromDB | null>(null);
    const [avatarSelected, setAvatarSelected] = useState<string>('');
    const [principalImage, setPrincipalImage] = useState<string>('');


    const avatarStyleStandard = {
        cursor: 'pointer'
    };

    const selectAvatarStyle = {
        cursor: 'pointer',
        border: `1px solid ${mainColor2}`
    }

    const handleSelectAvatar = (img: string | undefined | null) => {
        if (img) {
            setPrincipalImage(img);
            setAvatarSelected(img);
        }
    };

    useEffect(() => {
        setData(dataFromDescriptionModal);
        if (dataFromDescriptionModal?.image) {
            setAvatarSelected(dataFromDescriptionModal?.image.urlCloudinary);
            setPrincipalImage(dataFromDescriptionModal.image.urlCloudinary);
        } else if (dataFromDescriptionModal?.subImages?.length! > 0) {
            setAvatarSelected(dataFromDescriptionModal?.subImages![0].urlCloudinary!);
            setPrincipalImage(dataFromDescriptionModal?.subImages![0].urlCloudinary!);
        }
    }, [dataFromDescriptionModal?.id]);


    return (
        <div className='images-section'>

            {principalImage ?
                <div className='product-img-box'>
                    <LazyLoadImage className='product-img' src={`${principalImage}`} alt={data?.name} effect="blur" />
                </div>
                : <Typography>Hình ảnh đang được cập nhật</Typography>
            }

            <div className='all-product-img'>
                {(data?.image) &&
                    <Avatar
                        key={`avatar-${data?.image.id}`}
                        variant='square'
                        src={`${data?.image.urlCloudinary}`}
                        onClick={() => { handleSelectAvatar(data?.image!.urlCloudinary) }}
                        style={data?.image.urlCloudinary === avatarSelected ? selectAvatarStyle : avatarStyleStandard}
                    />
                }
                {data?.subImages?.map((img, i) =>
                    <Avatar
                        key={`avatar-${img.id}-${i}`}
                        variant='square'
                        src={`${img.urlCloudinary}`} onClick={() => handleSelectAvatar(img.urlCloudinary)}
                        style={img.urlCloudinary === avatarSelected ? selectAvatarStyle : avatarStyleStandard}
                    />
                )
                }
            </div>
        </div>
    );
}