import { Typography } from '@mui/material';

interface CustomizeTextProps {
    title?: string;
    subtitle?: string;
    text?: string;
    textColor?: string;
    textAlign?: "right" | "left" | "center" | "inherit" | "justify" | undefined;
}

export const CustomizeText = ({ title, subtitle, text, textColor, textAlign }: CustomizeTextProps) => {

    return (
        <>
            <Typography key={title} sx={{ mt: 2 }} fontWeight={'bold'} width={'100%'}>
                {title ? title : ''}
            </Typography >
            {text ? (text.split('\n').map((line, i) => 
            <Typography key={i} sx={{ mt: 0 }} style={{ marginLeft: '1rem', width: '100%' }} align={textAlign ? textAlign : 'justify'}>{line}</Typography>)) : ''}
        </>
    );
}