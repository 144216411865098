
import { Container } from '@mui/material';
import { Typography } from '@mui/material';
import { mainColor1, mC2Opacity1, textColor } from '../../utils/Theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import './Footer.scss';

export const Footer = () => {
    const isMediumLargeScreen = useMediaQuery('(max-width:1180px)');

    return (
        <div className='footer'>
            <Container
                className='footer-box'
                maxWidth='xl'
            >
                <Container className='footer-box__item'>
                    <img
                        className='logo'
                        src={`https://res.cloudinary.com/dyc65pvi8/image/upload/v1684058089/beautyshop/logo/logo_bg_green_2_azfudm.png`}
                        alt='logo shop green'
                    />
                    <Typography variant='h6' component='h3' align='center'>
                        Hương Thảo Beauty Shop chuyên phân phối sỉ và lẻ mỹ phẩm Fairy<br />
                    </Typography>
                    <Typography variant='h6' component='h2' align='center' color={mainColor1}>
                        Liên hệ tư vấn <br />
                    </Typography>
                    <Typography variant='h6' component='h3' align='center'>
                        <a href={`tel: 0939018486`} className='tel-btn'>09 39 01 84 86</a>
                    </Typography>
                    <div className='social-logo-line'>
                        <a className='social-logo' href="https://shopee.vn/myphamfairychinhhang?utm_content=222RBmdefBkrQxB4uQmThsjWSjf5" target={'_blank'}>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/logo/logo_shopee.png`}
                                alt='logo shopee'
                                style={{ width: 'auto' }}
                            />
                        </a>
                        <a href="https://zalo.me/0939018486" target={'_blank'}>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/logo/logo_zalo.png`}
                                alt='logo zalo'
                                style={{ width: 'auto' }}
                            />
                        </a>
                        <a href="https://www.facebook.com/thao.huong.180" target={'_blank'}>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/logo/logo_facebook.png`}
                                alt='logo facebook'
                                style={{ width: 'auto' }}
                            />
                        </a>
                    </div>
                </Container>

                <Container className='footer-box__item'>
                    <br />
                    <Typography variant='body2' component='h6' align='center'>
                        Chào mừng đến với Hương Thảo Beauty Shop ! <br /><br />
                        Chúng tôi cung cấp các sản phẩm chất lượng cao, an toàn và hiệu quả để giúp bạn có được làn da tươi trẻ. <br /><br />
                        Với một đội ngũ chuyên viên tư vấn làm đẹp giàu kinh nghiệm, chúng tôi luôn sẵn sàng tư vấn và hỗ trợ bạn trong việc chọn lựa sản phẩm phù hợp nhất với nhu cầu và tính trạng da của bạn.
                        Chúng tôi đảm bảo các sản phẩm của chúng tôi đều được kiểm định chất lượng nhằm mang lại sự hài lòng cho khách hàng.<br /><br />
                        Hơn thế nữa, chúng tôi còn cung cấp các chương trình chuyến mãi hấp dẫn và dịch vụ giao hàng tận nơi, giúp bạn có trải nghiệm mua sắm thuận tiện và tiết kiệm thời gian.
                    </Typography>
                </Container>
            </Container>

            <Typography variant='h6' component='h6' align='center'>
                2023 © Hương Thảo Beauty Shop, All Rights Reserved.
            </Typography>
        </div>
    );
}
