/*--- All colors used are the same with 
    src/assets/styles/utils/_variables.scss
----*/


const mainColor1: string = '#FFF'; //60
const mainColor2: string = '#3AC96E'; //30
const mainColor3: string = 'rgb(247, 107, 138)'; //10
const textColor: string = 'rgb(3, 10, 68)';
const backgroundColor1: string = 'rgb(255, 251, 243)';
const colorPromotion: string = '#ff3534';


// Color variant
const mC2Opacity05: string = 'rgba(18, 181, 96, 0.2)'

const mC2Opacity1 : string = 'rgba(18, 181, 96, 1)'

export { mainColor1, mainColor2, mainColor3, textColor, backgroundColor1, mC2Opacity05, mC2Opacity1, colorPromotion };