import * as React from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { DrawerItems } from '../../utils/Utils';
import OnlinePredictionRoundedIcon from '@mui/icons-material/OnlinePredictionRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import CategoryIcon from '@mui/icons-material/Category';

interface HomepageNestedList {
    data: any;
}

export default function HomepageNestedList({ data }: HomepageNestedList) {
    const [open, setOpen] = React.useState(true);

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginTop: '7rem' }}
            component='nav'
            aria-labelledby='nested-list-subheader'
        >
            <>
                <ListItemButton component={Link} to={'/'}>
                    <ListItemIcon>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/icons/icon_category.png`}
                            alt='icon category'
                            style={{ width: 'auto', height: '30px' }}
                    />
                    </ListItemIcon>
                    <ListItemText primary={DrawerItems[0].text} />
                </ListItemButton>
                {(DrawerItems[0].nestedItems.length > 0) && (
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding>
                            {DrawerItems[0].nestedItems.map((item, index) => (
                                <ListItemButton
                                    key={`category-${index}`}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        marginLeft: 2
                                    }}
                                    component={Link} to={`/san-pham/${item.pathname!}`}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >{index % 2 === 0 ? <OnlinePredictionRoundedIcon /> : <AutoAwesomeRoundedIcon />}

                                    </ListItemIcon>
                                    <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            ))}
                        </List>
                    </Collapse>
                )}

                <ListItemButton component={Link} to={'/san-pham/khuyen-mai'}>
                    <ListItemIcon>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/icons/icon_promotion.png`}
                            alt='icon promotion'
                            style={{ width: 'auto', height: '32px' }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={'Khuyến mãi'}
                    />
                </ListItemButton>
            </>
        </List>
    );
}