import { useState, useEffect } from 'react';
import {
    Form,
    Input,
    Radio,
    InputNumber,
    Button as ButtonAntd
} from 'antd';
import UploadButton from '../Upload/UploadButton';
import ModalInformation from '../ModalInformation/ModalInformation';
import { useMediaQuery } from '@mui/material';

const { TextArea } = Input;

interface CustomizeFormProps {
    initialValues?: any;
    typeAction: 'editProduct' | 'addProduct';
    imagePrincipal: any;
    fileList: any;
    resetForm: boolean;
    handleSubmitForm: (dataSubmitForm: any, typeAction: string) => void;
}


const CustomizeForm = ({ initialValues, resetForm, typeAction, imagePrincipal, fileList, handleSubmitForm }: CustomizeFormProps) => {

    const [form] = Form.useForm<any>();
    const [requestLoading, setRequestLoading] = useState<boolean>(false);
    const [uploadImage, setUploadImage] = useState<any>();
    const [uploadSubImages, setUploadSubImages] = useState<any>([]);
    const [showInformation, setShowInformation] = useState<boolean>(false);

    const isSmallScreen = useMediaQuery('(max-width:640px)');

    useEffect(() => {
        if (resetForm) {
            form.resetFields();
        }
    }, [resetForm]);


    const getFileListIds = (fileListIds: any, typeImage: string) => {
        if (typeImage === 'image') {
            setUploadImage(fileListIds);
        } else if (typeImage === 'subImages') {
            setUploadSubImages(fileListIds);
        }
    };

    // Onsubmit Form
    const onFinish = () => {
        const formData = form.getFieldsValue();
        const categorySaved = parseInt(formData.category);
        if (uploadImage.length === 0) {
            setShowInformation(true);
        } else {
            let dataSaved = {
                name: formData.name,
                imageId: uploadImage[0],
                subImageIds: uploadSubImages,
                description: formData.description,
                categoryId: categorySaved,
                price: formData.price,
                capacity: formData.capacity,
                origin: formData.origin ? formData.origin : '',
                expiredIn: formData.expiredIn ? formData.expiredIn : '',
                notice: formData.notice ? formData.notice : '',
                use: formData.use ? formData.use : '',
                usage: formData.usage ? formData.usage : '',
                ingredients: formData.ingredients ? formData.ingredients : '',
                program: formData.program ? formData.program : '',
                promotion: formData.promotion ? formData.promotion : 0,
            }
            handleSubmitForm(dataSaved, typeAction);
        }
    };

    const handleCloseModalInformation = () => {
        setShowInformation(false);
    }


    return (
        <>
            <Form
                initialValues={initialValues}
                form={form}
                onFinish={onFinish}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 20 }}
                layout='horizontal'
                style={{
                    width: '100%', overflowY: 'scroll', maxHeight: '90vh',
                    //  maxWidth: {isSmallScreen} ? '50%' : '100%' 
                }}
            >
                <Form.Item name='category' label='Loại sản phẩm' rules={[{ required: true, message: 'Chọn loại sản phẩm (bắt buộc)' }]}>
                    <Radio.Group>
                        <Radio value='1'> Chăm sóc da mặt </Radio>
                        <Radio value='2'> Chăm sóc body </Radio>
                        <Radio value='3'> Chăm sóc tóc </Radio>
                        <Radio value='4'> Trang điểm </Radio>
                        {/* <Radio value='5'> Thực phẩm dinh dưỡng </Radio> */}
                        {/* <Radio value='6'>Khac</Radio> */}
                        <Radio value='7'> Mặt nạ </Radio>
                        <Radio value='8'> SRM </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name='name' label='Tên sản phẩm' rules={[{ required: true, message: 'Bắt buộc' }]}>
                    <Input placeholder="Tên sản phẩm không được trùng"/>
                </Form.Item>

                <Form.Item name='price' label='Giá tiền' rules={[{ required: true, message: 'Bắt buộc' }]}>
                    <InputNumber min={1000} />
                </Form.Item>
                <Form.Item name='promotion' label='% khuyến mãi'>
                    <InputNumber min={0} max={100} placeholder="0 - 100"/>
                </Form.Item>
                <Form.Item name='capacity' label='Dung tích' rules={[{ required: true, message: 'Bắt buộc' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='description' label='Mô tả'>
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item label='Hình ảnh chính'
                // getValueFromEvent={normFile}
                >
                    <UploadButton typeImage={'image'} fileList={imagePrincipal} getFileListIds={getFileListIds} resetFileList={resetForm} />
                </Form.Item >
                <Form.Item label='Hình ảnh phụ'>
                    <UploadButton typeImage={'subImages'} fileList={fileList} getFileListIds={getFileListIds} resetFileList={resetForm} />
                </Form.Item >
                <Form.Item name='origin' label='Xuất xứ'>
                    <Input />
                </Form.Item>
                <Form.Item name='expiredIn' label='Hạn sử dụng'>
                    <Input />
                </Form.Item>
                <Form.Item name='notice' label='Lưu ý'>
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item name='use' label='Công dụng'>
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item name='usage' label='Cách sủ dụng'>
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item name='ingredients' label='Thành phần'>
                    <TextArea rows={4} style={{ whiteSpace: 'pre-wrap' }} />
                </Form.Item>
                <Form.Item name='program' label='Chương trình KM'>
                    <TextArea rows={4} style={{ whiteSpace: 'pre-wrap' }} />
                </Form.Item>
                <Form.Item>
                    <ButtonAntd type='primary' htmlType='submit' loading={requestLoading ? true : false}>Lưu </ButtonAntd>
                </Form.Item>
            </Form>

            <div style={{ zIndex: 1400, position: 'absolute' }}>
                <ModalInformation
                    open={showInformation}
                    onClose={handleCloseModalInformation}
                    informationText={'Bắt buộc phải có hình ảnh chính cho sản phẩm, không có là nó bug đó :v'}
                />
            </div>

        </>
    );

}

export default CustomizeForm;

