import { useState } from "react";
import withLayout from "../../HOC/withLayout";
import Header from "../../components/Header/Header";
import Cards from "../../components/Cards/Cards";
import ModalEditProduct from "../../components/ModalEditProduct/ModalEditProduct";
import { WithLayoutProps } from "../Homepage/HomepageView";
import { Button } from '@mui/material';


const Admin = ({ layout, data }: WithLayoutProps & any) => {

    // Modal Edit Product
    const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);

    const handleCloseModalEdit = () => {
        setOpenModalEdit(false);
    };

    return (
        <div style={{ marginTop: '20px' }}>
            <Header
                title={''}
            />
            <div style={{ position: 'fixed', top: '12%', right: '10%', zIndex: 200 }}>
                <Button
                    variant='outlined'
                    color="primary"
                    style={{ marginTop: '10px' }}
                    onClick={() => setOpenModalEdit(true)}
                >Thêm sản phẩm
                </Button>
                {openModalEdit &&
                    <ModalEditProduct
                        open={openModalEdit}
                        handleClose={handleCloseModalEdit}
                        typeAction={'addProduct'}
                    />
                }
            </div>
            <Cards
                data={data}
                isAdmin={true}
            />
        </div>

    )
}

export default withLayout(Admin);