import { useEffect, useState } from 'react';
import { Modal, Backdrop, Box, Fade, Button, Typography } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import { useMediaQuery } from '@mui/material';
import './ModalViewDescription.scss';
import { getProductById } from '../../api/product';
import { DataSingleCardFromDB } from '../../interfaces/interfaces';
import { CustomizeText } from './CustomizeText/CustomizeText';
import { ImagesSection } from './ImagesSection/ImagesSection';
import { PrincipalInfos } from './PrincipalInfos/PrincipalInfos';
import { mainColor1, mainColor2, mainColor3, textColor, backgroundColor1 } from '../../utils/Theme';


interface ModalViewDescriptionProps {
    productId: number;
    open: boolean;
    handleCloseModalDescription: () => void;
}

const ModalViewDescription = ({ productId, open, handleCloseModalDescription }: ModalViewDescriptionProps) => {

    // const { id, name, image, subImages, category, categoryCode, description, cardWidth, price, capacity } = props;

    // const [open, setOpen] = useState(false);
    const [data, setData] = useState<DataSingleCardFromDB | null>(null);
    const [categoryLabel, setCategoryLabel] = useState<string>('');
    const [avatarSelected, setAvatarSelected] = useState<string>('');
    const [principalImage, setPrincipalImage] = useState<string>('');
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);

    const isMediumLargeScreen = useMediaQuery('(max-width:1180px)');
    const isSmallScreen = useMediaQuery('(max-width:640px)');

    const styleBoxAbove = {
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        justifyContent: isSmallScreen ? 'center' : 'space-between',
        rowGap: isSmallScreen ? '20px' : 'unset',
        alignItems: 'center',
        width: '100%'
    }

    useEffect(() => {
        getProductById(productId).then(response => {
            setData(response?.data);
            if (response?.data.image?.urlCloudinary) {
                setAvatarSelected(response?.data.image?.urlCloudinary);
                setPrincipalImage(response?.data.image?.urlCloudinary);
            }

            setCategoryLabel(response?.data.category?.code);
        });
    }, [productId]);
    
    {
        if (data) {
            return (
                <div>
                    <Modal
                        aria-labelledby='transition-modal-title'
                        aria-describedby='transition-modal-description'
                        open={open}
                        onClose={handleCloseModalDescription}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                        style={{ zIndex: 1700 }}
                    >
                        <Fade in={open}>
                            <div className='box-modal'> 
                                <Box sx={styleBoxAbove}>
                                    <ImagesSection dataFromDescriptionModal={data} />
                                    <PrincipalInfos dataFromDescriptionModal={data} />
                                </Box>
                                <Box style={{ width: '90%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <Typography sx={{ mt: 2 }} variant='h6' component='h2' align={'left'} width={'100%'} fontWeight={'bold'} style={{ display: 'flex' }}>Thông tin sản phẩm</Typography>
                                    {data?.usage && <CustomizeText key={`usage-${productId}`} title='Công dụng : ' text={data?.usage} />}
                                    {data?.ingredients && <CustomizeText key={`ingredients-${productId}`} title='Thành phần : ' text={data?.ingredients} />}
                                    {data?.use && <CustomizeText key={`use-${productId}`} title='Hướng dẫn sử dụng : ' text={data?.use} />}
                                    {data?.origin &&
                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'left' }} >
                                            <Typography key={`origin-${productId}`} align={'left'} fontWeight={'bold'}>
                                                Xuất xứ : &nbsp;
                                            </Typography>
                                            <Typography>{data?.origin}</Typography>
                                        </div>
                                    }
                                    {data?.expiredIn &&
                                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'left' }} >
                                            <Typography key={`expiredIn-${productId}`} align={'left'} fontWeight={'bold'}>
                                                Hạn sử dụng : &nbsp;
                                            </Typography>
                                            <Typography>{data?.expiredIn}</Typography>
                                        </div>
                                    }
                                </Box>
                            </div>
                        </Fade>
                    </Modal>
                </div >
            );
        } else {
            return <div></div>
        }


    }
}
export default ModalViewDescription;