import axios from 'axios';
import { makeRequest } from './makeRequest';

const apiHost = process.env.REACT_APP_API_KEY;

/**** Action 
 * by Product Id
*****/

const getAllProducts = async () => {
  try {
    const response = await makeRequest.get(`/product`);
    const { data, status } = response;
    return { data, statusCode: status };
  } catch (error) {
    console.error(error);
    return { data: [] };
  }
};

const getSummaryAllProducts = async () => {
  try {
    const response = await makeRequest.get(`/product/summary`);
    const { data, status } = response;
    return { data, statusCode: status };
  } catch (error) {
    console.error(error);
    return { data: [] };
  }
};

const getProductById = async (productId: number) => {
  try {
    const response = await makeRequest.get(`/product/${productId}`);
    const { data, status } = response;
    return { data, statusCode: status };
  } catch (error) {
    console.error(error);
    return null;
  }
}

const createNewProduct = async (productData: any, token: string | null) => {
  if (token) {
    try {
      const response = await makeRequest.post(
        `${apiHost}/product`,
        productData,
        {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
      const { data, status } = response;
      return { data, statusCode: status };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return null;
};

const updateProductById = async (productId: number, productData: any, token: string | null) => {
  if (token) {
    try {
      const response = await makeRequest.put(
        `/product/${productId}`,
        productData, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      const { data, status } = response;
      return { data, statusCode: status };
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  return null;
};

const deleteProductById = async (productId: number, token: string | null) => {
  if (token) {
    try {
      const response = await makeRequest.delete(
        `/product/${productId}`, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      const { data, status } = response;
      return { data, statusCode: status };
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  return null;
};

/**** Action 
 * by Promotion
*****/
const getSummaryAllProductsPromotion = async () => {
  try {
    const response = await makeRequest.get(`/product/summary/promotion`);
    const { data, status } = response;
    return { data, statusCode: status };
  } catch (error) {
    console.error(error);
    return { data: [] };
  }
};

/**** Action 
 * by Category
*****/

const getProductSummaryByCategory = async (categoryId: number) => {
  try {
    const response = await makeRequest.get(`/product/summary/category/${categoryId}`);
    const { data, status } = response;
    return { data, statusCode: status };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export {
  apiHost,
  getAllProducts,
  getSummaryAllProducts,
  createNewProduct,
  updateProductById,
  deleteProductById,
  getProductById,
  getProductSummaryByCategory,
  getSummaryAllProductsPromotion
}