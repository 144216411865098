import { useEffect, useState, memo } from 'react';
import useHover from '../../utils/Hooks/useHover';
import { Container, Typography } from '@mui/material';
import CardProduct from './CardProduct/CardProduct';
import { SingleCardSummary } from '../../interfaces/interfaces';
import { StyledEngineProvider } from '@mui/material/styles';
import { mainColor1, mainColor2 } from '../../utils/Theme';
import { findCatFromCatCode } from '../../utils/Utils';
import './Cards.scss';

interface CardsProps {
    data: SingleCardSummary[]
    isAdmin: boolean;
}

const Cards = ({ isAdmin, data }: CardsProps) => {
    return (
        <StyledEngineProvider injectFirst>
            {data?.length > 0 ? (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <Typography variant='h6' component='h3' align='center' color={mainColor2} style={{ marginBottom: '50px', width: '100%', fontWeight: 'bold' }}>
                        {data[0].codeCategory === 'body'
                            ? (`Sản phẩm dành cho toàn thân (body)`).toUpperCase()
                            : (data[0].codeCategory === 'mask' || data[0].codeCategory === 'cleaning-gel'
                                ? findCatFromCatCode(data[0].codeCategory).toUpperCase()
                                : (`Sản phẩm dành cho ${findCatFromCatCode(data[0].codeCategory)}`).toUpperCase()
                            )
                        }
                        < br />
                    </Typography>
                    <Container
                        className='cards'
                        maxWidth="xl"
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                        }}
                    >
                        {data.map((dataCard: SingleCardSummary, i: number) => (
                            <CardProduct key={`${i}-${dataCard.id}`} {...dataCard} isAdmin={isAdmin} />
                        ))}
                    </Container>
                </div>
            ) : (
                <div style={{ marginLeft: '30px' }}>Sản phẩm đang được cập nhật</div>
            )
            }
        </StyledEngineProvider>
    )
}
export default memo(Cards);