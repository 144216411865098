import { useState, createContext, useContext, ReactNode } from 'react';

interface AuthContextType {
    isAuthenticated: any;
    setAuthenticated:any;
}

interface AuthUser {
    isAuthenticated: boolean;
    name: string;
    email: string;
    token: string;
    setAuthenticated: () => { };
}

const initialAuthContext: AuthContextType = {
    isAuthenticated: false,
    setAuthenticated: () => { },
};

export const AuthContext = createContext({} as AuthContextType);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: any) => {
    const [isAuthenticated, setAuthenticated] = useState<AuthContextType | null>(null);

    return (
        <AuthContext.Provider value={{ isAuthenticated, setAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};
