import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import SendIcon from '@mui/icons-material/Send';
import { DrawerItems } from '../../utils/Utils';
import OnlinePredictionRoundedIcon from '@mui/icons-material/OnlinePredictionRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import BallotIcon from '@mui/icons-material/Ballot';
import CategoryIcon from '@mui/icons-material/Category';
import PermMediaIcon from '@mui/icons-material/PermMedia';

interface NestedListProps {
  data: any;
  handleSelectCategory: (typeItemSelected: string, idItemSelected: number) => void
}

export default function NestedList({ data, handleSelectCategory }: NestedListProps) {
  const [open, setOpen] = React.useState(true);

  // const handleClick = (idItemSelected: number, nestedItemsLenght: number) => {
  //   if (nestedItemsLenght) {
  //     // setOpen(!open);
  //   } else {
  //     handleSelectCategory(idItemSelected);
  //   }
  // };

  return (
    <List key={'list'}
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {DrawerItems.map((item, index) => (
        <div key={`item-${item.name}`}>
          <ListItemButton key={item.name}>
            <ListItemIcon key={`item-icon-${item.name}`} >
              {index === 0 ? <BallotIcon /> : (index === 1 ? <CategoryIcon /> : <PermMediaIcon />)}
            </ListItemIcon>
            <ListItemText
              key={`item-text-${item.name}`}
              primary={item.text}
              onClick={() => handleSelectCategory(item.name, item.id)}
            />
          </ListItemButton>
          {(item.nestedItems.length > 0) && (
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {item.nestedItems.map((item, index) => (
                  <ListItemButton
                    key={item.label}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      marginLeft: 2
                    }}
                    onClick={() => handleSelectCategory('category', item.id)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >{index % 2 === 0 ? <OnlinePredictionRoundedIcon /> : <AutoAwesomeRoundedIcon />}

                    </ListItemIcon>
                    <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          )}
        </div>
      ))}
    </List>
  );
}