import { useState, useEffect, useContext } from 'react';
import { BottomScrollContext } from '../../context/BottomScrollContext '; 

const useBottomScrollListener = () => {
  const [isBottom, setIsBottom] = useState(false);
  const { setIsBottom: setContextIsBottom } = useContext(BottomScrollContext);

  useEffect(() => {
    function handleScroll() {
      const scrollTop =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop;
      const scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        document.body.scrollHeight;
      const clientHeight =
        document.documentElement.clientHeight || window.innerHeight;
      const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 200;

      setIsBottom(scrolledToBottom);
      setContextIsBottom(scrolledToBottom);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [setContextIsBottom]);

  return isBottom;
}

export default useBottomScrollListener;
