import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomepageContainer from './pages/Homepage/HomepageContainer';
import AdminContainer from './pages/Admin/AdminContainer';
import Login from './pages/Login/Login';
import PrivateRoutes from './HOC/PrivateRoutes';
import { useAuth } from './HOC/AuthenProvider';

function App() {
  const { isAuthenticated } = useAuth();
  return (

    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/admin" element={isAuthenticated ? <AdminContainer layout={false} /> : <Navigate to='/login' />} />
        </Route>
        <Route path="/" element={<HomepageContainer layout={true} />} />
        <Route path="/san-pham" element={<HomepageContainer layout={true} />} />
        <Route path="/san-pham/:category" element={<HomepageContainer layout={true} />} />
        <Route path="/login" element={<Login layout={false} />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;