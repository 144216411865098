import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import HomepageView from './HomepageView';
import HomepageNestedList from '../../components/CustomizeDrawer/HomepageNestedList';
import './HomepageDrawer.scss';
import { DataCategory } from '../../utils/Utils';
import { mainColor2 } from '../../utils/Theme';
import Banner from '../../components/Banner/Banner';
import withLayout from '../../HOC/withLayout';

import { useMediaQuery } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

const drawerWidth = 240;

interface DrawerProps {
  window?: () => Window;
}

export interface WithLayoutProps {
  layout: boolean;
};

const HomepageDrawer = ({ layout, data, pathname, pageName, window }: WithLayoutProps & any & DrawerProps) => {

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const isMediumLargeScreen = useMediaQuery('(max-width:1180px)');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      {!isMediumLargeScreen ?
        <HomepageView layout={layout} data={data} pageName={pageName} pageUrl={pathname} ></HomepageView>
        :
        <StyledEngineProvider injectFirst>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
              className={'drawer-appbar'}
              position="fixed"
              sx={{
                width: { lg: `calc(100% - ${drawerWidth}px)` },
                ml: { lg: `${drawerWidth}px` },
                backgroundColor: '#FFF',
                zIndex: 1500
              }}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { lg: 'none' } }}
                >
                  <MenuIcon style={{ color: mainColor2, fontSize: '40px' }} />
                </IconButton>

                {/* <ResponsiveAppBar></ResponsiveAppBar> */}
              </Toolbar>

            </AppBar>
            <Box
              component="nav"
              sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
              aria-label="mailbox folders"
            >
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: 'block', sm: 'block', lg: 'none', zIndex: 1500 },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                style={{ overflow: 'hidden' }}
              >
                <HomepageNestedList
                  data={DataCategory}
                ></HomepageNestedList>
              </Drawer>

              <Drawer
                variant="permanent"
                sx={{
                  // display: { xs: 'none', sm: 'none', lg: 'block' },
                  display: 'none',
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
              >
                <HomepageNestedList
                  data={DataCategory}
                ></HomepageNestedList>

              </Drawer>

            </Box>
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3, width: { lg: `calc(100% - ${drawerWidth}px)` } }}
              style={{ padding: 0, overflow: 'hidden' }}
            >
              <HomepageView layout={layout} data={data} pageName={pageName} pageUrl={pathname} ></HomepageView>
            </Box>
          </Box>
        </StyledEngineProvider>
      }
    </>
  );
}

export default withLayout(HomepageDrawer)
