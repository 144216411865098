import { Modal } from 'antd';

interface ModalConfirmProps {
    itemId: number;
    open: boolean;
    onClose: () => void;
    onDelete: (id: number) => void;
}

const ModalConfirm = ({ itemId, open, onClose, onDelete }: ModalConfirmProps) => {

    const handleOk = () => {
        onDelete(itemId);
        onClose();
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <>
            <Modal
                title="Confirm Delete"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>Xoá sản phẩm vĩnh viễn ?</p>
            </Modal>
        </>
    );
};

export default ModalConfirm;
