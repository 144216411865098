
import { Chip, Typography } from '@mui/material';
import { findColorByCat } from '../../../utils/Utils';
import { DataSingleCardFromDB } from '../../../interfaces/interfaces';
import { formatNumber } from '../../../utils/Utils';
import { mainColor2, colorPromotion } from '../../../utils/Theme';
import './PrincipalInfos.scss';

interface PrincipalInfosProps {
    dataFromDescriptionModal: DataSingleCardFromDB | null;
}

export const PrincipalInfos = ({ dataFromDescriptionModal }: PrincipalInfosProps) => {

    return (

        <div
            className='principal-infos'
            style={{ border: `1px solid ${findColorByCat(dataFromDescriptionModal?.category?.code)}` }}
        >
            <Chip
                label={dataFromDescriptionModal?.category.label}
                sx={{
                    borderRadius: '5px',
                    textTransform: 'uppercase',
                    color: '#FFF',
                    backgroundColor: `${findColorByCat(dataFromDescriptionModal?.category?.code)}`
                }} />

            <Typography variant='h6' component='h2'>
                {dataFromDescriptionModal?.name}
            </Typography>

            <div
                className='price'
                style={{
                    borderTop: `1px solid ${findColorByCat(dataFromDescriptionModal?.category?.code)}`,
                    borderBottom: `1px solid ${findColorByCat(dataFromDescriptionModal?.category?.code)}`,
                }}
            >
                {/* <Typography>
                    {formatNumber(dataFromDescriptionModal?.price)} vnđ
                </Typography> */}

                {(dataFromDescriptionModal?.promotion !== undefined && dataFromDescriptionModal?.promotion !== null && dataFromDescriptionModal?.promotion !== 0)
                    ? (<>
                        <Typography className='price__line'>
                            <span className='old-price'>{formatNumber(dataFromDescriptionModal?.price)} vnđ</span>
                        </Typography>
                        <Typography sx={{ color: colorPromotion, fontWeight: 'bold' }}>
                            &nbsp; {formatNumber((1 - dataFromDescriptionModal?.promotion / 100) * dataFromDescriptionModal?.price)} vnđ
                        </Typography>
                    </>)
                    : (
                        <Typography>
                            {formatNumber(dataFromDescriptionModal?.price)} vnđ
                        </Typography>
                    )
                }

                {dataFromDescriptionModal?.capacity &&
                    <Typography>
                        &nbsp; / {dataFromDescriptionModal?.capacity}
                    </Typography>
                }

            </div>

            {dataFromDescriptionModal?.program &&
                <Typography
                    sx={{ mt: 2 }}
                    className='promotion'
                    style={{
                        backgroundColor: colorPromotion,
                        color: '#FFF',
                        width: '90%'
                    }}
                >{dataFromDescriptionModal?.program}
                </Typography>
            }

            <Typography sx={{ mt: 2 }}>{dataFromDescriptionModal?.description}</Typography>

            <a href={`tel: 0939018486`} className='tel-btn'>
                <Chip
                    label={'Liên hệ ngay : 09 39 01 84 86'}
                    sx={{
                        width: '18rem',
                        fontSize: '1rem',
                        fontWeight: '500',
                        minHeight: '3rem',
                        backgroundColor: mainColor2,
                        color: '#FFF',
                        justifyContent: 'space-around',
                        marginTop: '1rem',
                        '& .MuiChip-label': {
                            padding: '1rem'
                        }
                    }}
                />
            </a>

        </div>
    );
}