import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { getSummaryAllProducts, getProductSummaryByCategory, getSummaryAllProductsPromotion } from "../../api/product";
import { WithLayoutProps } from "./HomepageView";
import { SingleCardSummary } from "../../interfaces/interfaces";
import { findCategoryCodeFromPathname, findPageName, findProductsWithSameCategory } from "../../utils/Utils";
import HomepageDrawer from "./HomepageDrawer";
import useBottomScrollListener from "../../utils/Hooks/useBottomScrollListener";
import { BottomScrollContext } from "../../context/BottomScrollContext ";


const HomepageContainer = ({ layout }: WithLayoutProps) => {
    const location = useLocation();
    const { pathname } = location;
    const [data, setData] = useState<SingleCardSummary[] | SingleCardSummary[][]>([]);
    const isBottom = useBottomScrollListener();

    const [pageName, setPageName] = useState<string>('');

    useEffect(() => {
        if (pathname.split('/').includes('admin' || 'login')) {
            return;
        };

        if (pathname.split('/').includes('san-pham')) {
            if (pathname.split('/').includes('khuyen-mai')) {
                setPageName('Sản phẩm / Khuyến mãi');
                getSummaryAllProductsPromotion().then(response => { setData(findProductsWithSameCategory(response.data)); });
                return;
            } else {
                let categoryCode: number | undefined = findCategoryCodeFromPathname(pathname.split('/')[2]);
                setPageName('Sản phẩm / ' + findPageName(pathname.split('/')[2]));
                if (categoryCode) {
                    getProductSummaryByCategory(categoryCode).then(response => {
                        response === null ? setData([]) : setData(response!.data)
                    });
                    return;
                };
                getSummaryAllProducts().then(response => { setData(findProductsWithSameCategory(response.data)); });
            }
        };

        getSummaryAllProducts().then(response => {
            setData(findProductsWithSameCategory(response.data));
        });

    }, [pathname]);

    return (
        <BottomScrollContext.Provider value={{ isBottom, setIsBottom: () => { } }}>
            <HomepageDrawer layout={layout} data={data} pageName={pageName} pageUrl={pathname} />
        </BottomScrollContext.Provider>
    )
}

export default (HomepageContainer);