import { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
// import type {  RcFile, UploadFile, UploadProps } from 'antd';
import { Button, message, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { apiHost } from '../../api/product';


const UploadButton = ({ ...props }) => {
  const [display, setDisplay] = useState<boolean | null>(true);
  const [fileList, setFileList] = useState<UploadFile[]>(props.fileList);
  const [fileListIds, setFileListIds] = useState<any>([]);



  const propsUploadImage: UploadProps = {
    name: 'file',
    action: `${apiHost}/image/upload`,
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('bearer-token'),
    },
    async onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }

      if (info.file.status === 'done') {
        // console.log(info.file.status);
        message.success(`${info.file.name} file uploaded successfully`);

        try {
          const response = await info.file.response; // wait for the Promise to resolve
        } catch (error) {
          // console.error(error);
        }

      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        // console.log(message);
      }
      // console.log(info.file.response);
      setFileList([...info.fileList]);
    }
  };

  const onRemove = (file: UploadFile) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  useEffect(() => {
    if (props.typeImage === 'image') {
      setDisplay(false);
    } else setDisplay(true);
    return () => { setDisplay(null) };
  }, []);

  useEffect(() => {
    if (props.typeImage === 'image' && fileList.length < 1) {
      setDisplay(true);
    } else if (props.typeImage === 'image' && fileList.length >= 1) {
      setDisplay(false);
    };

    let tmp: any = [];

    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].response === undefined) {
        if (!isNaN(Number(fileList[i].uid))) {
          tmp.push(+fileList[i].uid);
        }
      } else if (fileList[i].response.status === 'done') {
        tmp.push(+fileList[i].response.id);
      } else return;
    };  
    setFileListIds(tmp);
  }, [fileList]);

  useEffect(() => {
    props.getFileListIds(fileListIds, props.typeImage);
  }, [fileListIds]);

  useEffect(() => {
    if(props.resetFileList) {
      setFileList([]);
    }
  },[props.resetFileList]);

  return (
    <Upload
      listType="picture-card"
      fileList={fileList}
      onRemove={onRemove}
      {...propsUploadImage}>
      {display &&
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Tải lên</div>
        </div>}
    </Upload>
  )
}

export default UploadButton;