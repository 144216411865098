import { useEffect, useState, createContext, Dispatch, SetStateAction } from "react";
import { getAllProducts, getSummaryAllProducts, getProductSummaryByCategory, getSummaryAllProductsPromotion } from "../../api/product";
import Admin from "./AdminView";
import { WithLayoutProps } from "../Homepage/HomepageView";
import AdminDrawer from "../../components/CustomizeDrawer/AdminDrawer";
import UploadButton from "../../components/Upload/UploadButton";
import { UploadFile } from "antd";
import { Image, DataSingleCardFromDB, SingleCardSummary, Category } from "../../interfaces/interfaces";



interface UpdateDataContext {
    data: SingleCardSummary[];
    setData: Dispatch<SetStateAction<SingleCardSummary[]>>;
}

export const UpdateData = createContext<UpdateDataContext>({
    data: [],
    setData: () => { },
});

const AdminContainer = ({ layout }: WithLayoutProps) => {
    const [data, setData] = useState<SingleCardSummary[]>([]);
    const [itemSelected, setItemSelected] = useState<number | null>(null);
    const [typeSelected, setTypeSelected] = useState<string | null>(null);

    // useEffect(() => {
    //     getSummaryAllProducts().then(response => { setData(response.data) });
    // }, []);

    const handleSelectCategory = (typeItemSelected: string, itemSelectedId: number) => {
        setItemSelected(itemSelectedId);
        setTypeSelected(typeItemSelected);
    }

    useEffect(() => {
        if (typeSelected === 'category' && itemSelected) {
            getProductSummaryByCategory(itemSelected).then(response => {
                response === null ? setData([]) : setData(response!.data)
            });
            return;
        } else if (typeSelected === 'promotion') {
            getSummaryAllProductsPromotion().then(response => { setData(response.data) });
            return;
        }
        getSummaryAllProducts().then(response => { setData(response.data) });
    }, [itemSelected, typeSelected]);

    return (
        <UpdateData.Provider value={{ data, setData }}>
            <AdminDrawer
                layout={layout}
                data={data}
                handleSelectCategory={handleSelectCategory}
            />
        </UpdateData.Provider>

    )
}

export default AdminContainer;