import { forwardRef, useContext, useState, memo, useEffect } from 'react';
import useHover from '../../../utils/Hooks/useHover';
import { Box, Chip, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import { findCatFromCatCode, findColorByCat, formatNumber, truncateText } from '../../../utils/Utils';
import ModalViewDescription from '../../ModalViewDescription/ModalViewDescription';
import ModalEditProduct from '../../ModalEditProduct/ModalEditProduct';
import ModalConfirm from '../../ModalConfirm/ModalConfirm';
import { deleteProductById } from '../../../api/product';
import { UpdateData } from '../../../pages/Admin/AdminContainer';
import { SingleCardSummary } from '../../../interfaces/interfaces';
import { mainColor2, textColor, colorPromotion, mainColor1 } from '../../../utils/Theme';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { BEARER_TOKEN } from '../../../utils/Constants';



import './CardProduct.scss';

export interface CardProps extends SingleCardSummary {
    cardWidth?: number;
    isAdmin?: boolean;
}

const CardProduct = forwardRef((props: CardProps) => {
    const mdScreen = useMediaQuery('(min-width:821px)');
    const { id, name, urlCloudinary, codeCategory, description, price, capacity, promotion, program, isAdmin } = props;
    const isMediumLargeScreen = useMediaQuery('(max-width:1180px)');

    // Use useContext to transfert update when product deleted
    const { data, setData } = useContext(UpdateData);
    const [hover, isHovered] = useHover<HTMLDivElement>();

    // Modal View Description
    const [openModalDescription, setModalDescription] = useState<boolean>(false);

    const handleOpenModalDescription = () => {
        setModalDescription(true);
        setSelectedItemId(id);
    }

    const handleCloseModalDescription = () => {
        setModalDescription(false);
        setSelectedItemId(null);
    };

    // Modal Edit Product
    const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);

    const handleOpenModalEdit = () => {
        setOpenModalEdit(true);
        setSelectedItemId(id);
    }

    const handleCloseModalEdit = () => {
        setOpenModalEdit(false);
        setSelectedItemId(null);
    };

    // Modal Confirm Delete
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);

    const handleOpenModalConfirm = (productId: number) => {
        setOpenModalConfirm(true);
        setSelectedItemId(productId);
    };

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
        setSelectedItemId(null);
    };

    const handleDeleteProduct = (productId: number) => {
        deleteProductById(productId, localStorage.getItem(BEARER_TOKEN));
        setData(data.filter(product => product.id !== productId));
        setOpenModalConfirm(false);
        setSelectedItemId(null);
    };

    const styleCategoryChip = {
        borderRadius: '5px',
        textTransform: 'uppercase',
        color: '#FFF',
        height: `${mdScreen ? '30px' : '20px'}`,
        zIndex: '5',
        position: 'absolute',
        top: '2px',
        backgroundColor: codeCategory ? `${findColorByCat(codeCategory)}` : mainColor2,
    };

    return (
        <>
            <Box
                ref={!isAdmin ? hover : null}
                className={`${isAdmin ? 'Box' : 'Box Box--hovered-effect'}`}
                sx={{
                    textAlign: 'center'
                }}
            >
                <div
                    className='box-item'
                    style={
                        (!isAdmin && isHovered)
                            ? { opacity: 0 }
                            : { display: 'flex', flexDirection: 'column', alignItems: 'center' }
                    }
                >
                    {(promotion || program) &&
                        // (<Chip
                        //     label='promotion'
                        //     sx={styleCategoryChip}
                        //     style={{ left: '2px', backgroundColor: colorPromotion }}
                        // />)
                        <img
                            src={`${process.env.PUBLIC_URL}/images/icons/icon_promotion.png`}
                            alt="icon_promotion"
                            style={{ width: 'auto', height: '32px', position: 'absolute', left: '2px', top: '2px', zIndex: 10 }}
                        />
                    }
                    <Chip
                        label={findCatFromCatCode(codeCategory)}
                        sx={styleCategoryChip}
                        style={{ right: '2px', backgroundColor: `${findColorByCat(codeCategory)}` }}
                    />

                    <div
                        className='box-image'
                        style={{ position: 'relative' }}
                    >
                        <LazyLoadImage
                            className='product-image'
                            src={`${urlCloudinary}`}
                            alt={name}
                            effect="blur"
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        rowGap: '12px',
                        width: '90%'
                    }}
                    >
                        <div className='box-name' style={{ color: `${findColorByCat(codeCategory)}`, lineHeight: 1.5, fontSize: '1rem' }}>
                            {name && truncateText(name, 80)}

                        </div>
                        {(promotion !== undefined && promotion !== 0 && promotion !== null)
                            ? (
                                <div className='box-price box-price--old'>
                                    <span className="old-price">{formatNumber(price)} vnđ</span>
                                </div>
                            )
                            : (
                                <div className='box-price'>
                                    {formatNumber(price)} vnđ
                                </div>
                            )
                        }
                        {(promotion !== undefined && promotion !== 0 && promotion !== null) &&
                            <div className='box-price--promotion'>
                                {formatNumber((1 - promotion / 100) * price)} vnđ
                            </div>
                        }

                        {(program !== undefined && program) &&
                            <div className='box-program'>
                                {program}
                            </div>
                        }
                    </div>

                    {isAdmin &&
                        <> {openModalEdit &&

                            <ModalEditProduct
                                productId={id}
                                open={openModalEdit}
                                handleClose={handleCloseModalEdit}
                                typeAction={'editProduct'}
                            />
                        }
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                                <Button
                                    variant='outlined'
                                    color="primary"
                                    style={{ marginTop: '10px' }}
                                    onClick={handleOpenModalEdit}
                                > <BorderColorTwoToneIcon />
                                </Button>
                                <Button
                                    variant='outlined'
                                    color="error"
                                    style={{ marginTop: '10px' }}
                                    onClick={() => handleOpenModalConfirm(id)}
                                ><DeleteIcon />
                                </Button>
                            </div>

                            <ModalConfirm
                                itemId={selectedItemId!}
                                open={openModalConfirm}
                                onClose={handleCloseModalConfirm}
                                onDelete={handleDeleteProduct} />
                        </>}
                </div>

                {!isAdmin &&
                    <div
                        className='box-item--hovered'
                        style={!isHovered ? { opacity: 0 } : { opacity: 1, color: textColor, lineHeight: 1.5, fontSize: '1rem' }}>
                        {description && truncateText(description, 200)}

                        <Button
                            onClick={handleOpenModalDescription}
                            variant='outlined'
                            startIcon={
                                <ZoomInIcon />}
                            sx={isMediumLargeScreen ? {
                                '& .css-1d6wzja-MuiButton-startIcon': {
                                    margin: '0px !important'
                                },
                            } : {}}
                            style={{ color: mainColor1, backgroundColor: mainColor2, border: `1px solid ${mainColor1}` }}>
                            {!isMediumLargeScreen && 'Tìm hiểu thêm'}
                        </Button>

                        {openModalDescription &&
                            <ModalViewDescription
                                productId={id}
                                open={openModalDescription}
                                handleCloseModalDescription={handleCloseModalDescription}
                            />
                        }
                    </div>
                }

            </Box>
        </>
    )
})

export default memo(CardProduct);