import { useState, useEffect, useContext } from 'react';
import { Button, Modal, Box } from '@mui/material';
import { Form } from 'antd';
import { createNewProduct, updateProductById } from '../../api/product';
import { transformDataToDataForm, transformDataToDataSingleCardSummary } from '../../utils/Utils';
import { UpdateData } from '../../pages/Admin/AdminContainer';
import Notification from '../Notification/Notification';
import { UploadFile } from 'antd';
import { getProductById } from '../../api/product';
import CustomizeForm from '../CustomizeForm/CustomizeForm';
import { BEARER_TOKEN } from '../../utils/Constants';

interface ModalEditProductProps {
    productId?: number | null;
    open: boolean;
    handleClose: () => void;
    typeAction: 'editProduct' | 'addProduct';
    buttonSave?: string;
}

const ModalEditProduct = ({ open, handleClose, typeAction, buttonSave, productId }: ModalEditProductProps) => {

    const [form] = Form.useForm<any>();

    // Use useContext to transfert update when product added
    const { data, setData } = useContext(UpdateData);
    const [initialValues, setInitialValues] = useState<any>();

    // Set image default
    const [imagePrincipal, setImagePricipal] = useState<UploadFile[]>([]);
    // Set list images default
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const [success, setSuccess] = useState<boolean>(false);
    const [openModalInfo, setOpenModalInfo] = useState<boolean>(false);
    const [resetForm, setResetForm] = useState<boolean>(false);


    const closeModalInfo = () => {
        setOpenModalInfo(false);
        setTimeout(() => {
            setSuccess(false);
        }, 6000);
    };

    const handleSubmitForm = (dataFormSubmit: any, typeAction: string) => {
        (typeAction === 'editProduct')
            ? (updateProductById(initialValues?.id, dataFormSubmit, localStorage.getItem(BEARER_TOKEN)).then(response => {

                if (response !== null) {
                    if (response.statusCode === 200) {
                        let tmp = updateListDataFront(data, response.data);
                        setData(tmp);
                        setSuccess(true);
                        setOpenModalInfo(true);
                    } else {
                        setSuccess(false);
                        setOpenModalInfo(true);
                    }
                } else {
                    setSuccess(false);
                    setOpenModalInfo(true);
                }

            })
            )
            : (createNewProduct(dataFormSubmit, localStorage.getItem(BEARER_TOKEN)).then(response => {
                if (response !== null) {
                    if (response.statusCode === 201) {
                        let newProductSummary = transformDataToDataSingleCardSummary(response.data!);
                        setSuccess(true);
                        setOpenModalInfo(true);
                        setResetForm(true);
                        setData([...data, newProductSummary!]);

                    } else {
                        setSuccess(false);
                        setOpenModalInfo(true);
                    }
                } else {
                    setSuccess(false);
                    setOpenModalInfo(true);
                }
            })
            );
    };

    // Get data to fill Form
    useEffect(() => {
        if (productId && open) {
            let imagePrincipalArray: any = [];
            getProductById(productId)
                .then(response => transformDataToDataForm(response?.data))
                .then(dataForm => {
                    setInitialValues(dataForm);
                    if (typeAction === 'editProduct') {
                        imagePrincipalArray.push(dataForm!.image);
                        setImagePricipal(imagePrincipalArray);
                        if (dataForm!.subImages) {
                            setFileList(dataForm!.subImages);
                        }
                    }
                });
        } else {
            setInitialValues({});
            setImagePricipal([]);
            setFileList([]);
        };
    }, [productId, open]);


    const updateListDataFront = (products: any, product: any): any => {
        return products.map((prod: any) => {
            if (prod.id === productId) {
                prod.name = product.name;
                prod.urlCloudinary = product.image.urlCloudinary;
                prod.description = product.description;
                prod.category = product.categoryId;
                prod.price = product.price;
                prod.capacity = product.capacity;
                prod.promotion = product.promotion;
                prod.program = product.program;
            }
            return prod;
        });
    };

    if (initialValues) {
        return (
            <div>
                <Modal open={open} slotProps={{ backdrop: { open: false } }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: 1180, minWidth: 600, width: '100%', bgcolor: 'background.paper', boxShadow: 24, p: 4, display: 'flex', justifyContent: 'center' }}>
                        <CustomizeForm
                            initialValues={initialValues}
                            typeAction={typeAction}
                            imagePrincipal={imagePrincipal}
                            fileList={fileList}
                            resetForm={resetForm}
                            handleSubmitForm={handleSubmitForm}
                        ></CustomizeForm>
                        <Button
                            variant='outlined'
                            style={{ maxWidth: '200px', right: 0, position: 'fixed', bottom: '0', left: '50%', transform: 'translate(-50%, -50%)' }}
                            onClick={() => handleClose()}>Đóng
                        </Button>
                        <Notification isOpen={openModalInfo} isSuccess={success} closeModalInfo={closeModalInfo} />
                    </Box>
                </Modal>
            </div>
        );
    } else {
        return <div></div>
    }

}

export default ModalEditProduct;

