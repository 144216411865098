import { useEffect } from "react";
import { Outlet, Navigate } from "react-router";
import { useAuth } from "./AuthenProvider";

const PrivateRoutes = () => {
    const { isAuthenticated, setAuthenticated } = useAuth();

    return (
        isAuthenticated ? <Outlet/> : <Navigate to='/login' />
    )
}

export default PrivateRoutes;