import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Cards from "../../components/Cards/Cards";
import withLayout from "../../HOC/withLayout";
import { FloatButton } from "../../components/FloatButton/FloatButton";
import IconBreadcrumbs from "../../components/BreadCrumb/IconBreadcrumbs";
import Banner from "../../components/Banner/Banner";

export interface WithLayoutProps {
    layout: boolean;
};

const Homepage = ({ data, pathname, pageName, handlePageUrl }: any) => {

    return (
        <>
            <Banner></Banner>
            <FloatButton />
            <IconBreadcrumbs pageName={pageName} pageUrl={pathname} handlePageUrl={handlePageUrl}></IconBreadcrumbs>
            <Header title={''} />
            {
                data && (Array.isArray(data[0])
                    ? data.map((item: any) => <Cards
                        data={item}
                        isAdmin={false}
                    />) : <Cards
                        data={data}
                        isAdmin={false}
                    />)
            }
        </>
    )
}

export default withLayout(Homepage);