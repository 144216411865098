import { Modal } from 'antd';

interface ModalInformationProps {
    open: boolean;
    onClose: () => void;
    informationText ? : string;
}

const ModalInformation = ({ open, onClose, informationText }: ModalInformationProps) => {

    const handleOk = () => {
        onClose();
    };

    const handleCancel = () => {
        onClose();
    };

    return (
            <Modal
                title="Attention"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                zIndex={1400}
            >
                <p>{informationText}</p>
            </Modal>
    );
};

export default ModalInformation;
